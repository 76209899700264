import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

// For a component that has no internal state (only uses properties) and only needs
// a render function, make it into a function component
function Square(props) {
  return(
    <button className="square" onClick={() => props.onClickMethod()}>
      {props.value}
    </button>
  );
}

class Board extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      squareValues: Array(9).fill(null),
      currentPlayerX: true,
    };
  }

  renderSquare(i) {
    return (
      // have to pass a method for child to call because child can't call back to parent when it's clicked
      <Square 
        value={this.state.squareValues[i]} 
        onClickMethod={() => this.handleClick(i)}
      />);
  }

  render() {
    const winner = calculateWinner(this.state.squareValues);
    let status;
    if (winner != null) {
      status = winner + ' wins!';
    } else {
      status = 'Next player: ' + (this.state.currentPlayerX ? 'X' : 'O');
    }

    return (
      <div>
        <div className="status">{status}</div>
        <div className="board-row">
          {this.renderSquare(0)}
          {this.renderSquare(1)}
          {this.renderSquare(2)}
        </div>
        <div className="board-row">
          {this.renderSquare(3)}
          {this.renderSquare(4)}
          {this.renderSquare(5)}
        </div>
        <div className="board-row">
          {this.renderSquare(6)}
          {this.renderSquare(7)}
          {this.renderSquare(8)}
        </div>
      </div>
    );
  }

  handleClick(i) {
    // Instead of directly editing state, use setState so that re-renders
    // are always done when data is changed
    const squareValuesCopy = this.state.squareValues.slice();
    if (calculateWinner(squareValuesCopy) != null || squareValuesCopy[i] != null) {
      return;
    }
    squareValuesCopy[i] = this.state.currentPlayerX ? 'X' : 'O';
    this.setState(
      {
        squareValues: squareValuesCopy,
        currentPlayerX: !this.state.currentPlayerX,
      });
    fetch('https://whitecliffgames.com/api/games.php')
      .then((response) => {
        if (response.status == 200) {
          response.json()
            .then((parsedResponse) => console.log(parsedResponse));
        }
      });
  }
}

class Game extends React.Component {
  render() {
    return (
      <div className="game">
        <div className="game-board">
          <Board />
        </div>
        <div className="game-info">
          <div>{/* status */}</div>
          <ol>{/* TODO */}</ol>
        </div>
      </div>
    );
  }
}

// ========================================

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Game />);

function calculateWinner(squares) {
  const lines = [
    [0, 1, 2],
    [3, 4, 5],
    [6, 7, 8],
    [0, 3, 6],
    [1, 4, 7],
    [2, 5, 8],
    [0, 4, 8],
    [2, 4, 6],
  ];
  for (let i = 0; i < lines.length; i++) {
    const [a, b, c] = lines[i];
    if (squares[a] != null && squares[a] === squares[b] && squares[a] === squares[c]) {
      return squares[a];
    }
  }
  return null;
}